<template lang="pug">
div(
    class="step-9"
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- boxes
    Container
        Spacer(
            small
        )
        IonRow(class="receding")
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="tertiary"
                )
                    h3(class="with-margin") Kunde
                Box(
                    style="background-color: var(--ion-color-accent)"
                )
                    IonText(
                        color="white"
                    )
                        div(class="mb-2") Frau Karin Weimann
                        div Wielandstrasse 13
                        div 70130 Stuttgart

            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="tertiary"
                )
                    h3(class="with-margin") Rapportdaten
                Box(
                    style="background-color: var(--ion-color-accent)"
                )
                    IonText(
                        color="white"
                    )
                        div(class="mb-2") Monteur:
                        div Datum:

    //- jobs
    div
        Spacer
        OrderLines(
            :order="order"
        )

    //- tabs
    div
        Spacer
        Spacer
        Container
            div(class="d-flex align-center")
                IonSegment(
                    v-model="tab"
                    :scrollable="true"
                    style="flex-grow: 1"
                )
                    IonSegmentButton(
                        value="cause"
                    )
                        IonLabel(
                            color="white"
                        ) Auftragsgrund
                    IonSegmentButton(
                        value="remarks"
                    )
                        IonLabel(
                            color="white"
                        ) Anmerkungen

                div(
                    style="margin-left: 15px"
                )
                    IonButton(
                        class="round-btn"
                        color="secondary"
                        style="width: 30px; height: 30px"
                        @click="openEditRemarksModal"
                    )
                        CustomIcon(
                            fileName="edit.png"
                        )

        SegmentCard(
            style="background-color: var(--ion-color-primary-shade)"
        )
            div(
                v-if="tab == 'cause'"
            ) {{ order.cause }}
            div(
                v-if="tab == 'remarks'"
            )
                div {{ order.remarks }}
                NoUploads(
                    v-if="!order.remarks"
                )
                    div Keine Anmerkungen

    //- buttons
    div(class="buttons-height-2")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="12"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-report', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Rapport anzeigen
    CustomModal(
        v-model="editRemarksModal"
    )
        ConfirmModalContentWithSlot(
            @confirm="updateRemarks(order, editedText)"
            @decline="closeEditRemarksModal"
            secondVariant
        )
            div(
                class="d-flex flex-column"
                style="height: 100%"
            )
                Spacer
                IonTextarea(
                    style="flex-grow: 1; --background: white"
                    v-model="editedText"
                    class="radius"
                )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import CustomRow from "@/components/molecules/CustomRow"
import Box from "@/components/molecules/Box"
import OrderLines from "@/components/molecules/OrderLines"
import SegmentCard from "@/components/molecules/SegmentCard"
import CustomIcon from "@/components/molecules/CustomIcon"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import NoUploads from "@/components/molecules/camera-uploads/NoUploads"

import { chevronForwardCircle } from "ionicons/icons"
import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"
import { useState } from "@/composables/state.js"
export default {
    components: {
        TopBar,
        OrderName,
        CheckableButton,
        Box,
        CustomRow,
        OrderLines,
        SegmentCard,
        CustomIcon,
        CustomModal,
        ConfirmModalContentWithSlot,
        NoUploads
    },
    props: {
        order: {
            required: true
        }
    },

    setup(props) {
        chevronForwardCircle
        const tab = ref("cause")

        const emitter = useUtilities().getEmitter()

        // remarks edit
        const editedText = ref(null)
        const editRemarksModal = ref(false)

        const { updateOrder } = useState()

        function openEditRemarksModal() {
            editRemarksModal.value = true
            editedText.value = JSON.parse(JSON.stringify(props.order.remarks))
        }

        function closeEditRemarksModal() {
            editRemarksModal.value = false
            editedText.value = null
        }

        async function updateRemarks() {
            emitter.emit("setLoadingModal", true)
            const orderCopy = JSON.parse(JSON.stringify(props.order))
            orderCopy.remarks = editedText.value
            await updateOrder(orderCopy)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
            closeEditRemarksModal()
        }

        return {
            tab,
            chevronForwardCircle,
            editRemarksModal,
            updateRemarks,
            editedText,
            openEditRemarksModal,
            closeEditRemarksModal
        }
    }
}
</script>