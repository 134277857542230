<template lang="pug">
BaseLayout
    Step7(
        :order="currentOrder"
    )
</template>

<script>
import Step7 from "@/components/sections/order-processing/Step7"

import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        Step7
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>